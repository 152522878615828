import { memo } from 'react';
import { useTheme } from 'styled-components';
import { LoaderContainer, Spinner } from './LoaderStyle';

interface Props {
  height?: string;
  width?: string;
  borderWidth?: number;
  color?: string;
}

const LoaderCircle = ({
  height = '24px',
  width = '24px',
  borderWidth,
  color,
  ...props
}: Props) => {
  const theme = useTheme();

  return (
    <LoaderContainer>
      <Spinner
        height={height}
        width={width}
        borderWidth={borderWidth}
        color={color}
      />
    </LoaderContainer>
  );
};

export default memo(LoaderCircle);
