import {
  PAYMENT_GATEWAYS,
  PAYMENT_GATEWAYS_TYPE,
} from '../constants/paymentGateways';

export const getPaymentMethodName = (pmId: number): PAYMENT_METHOD_TYPES => {
  const env = process.env.NEXT_PUBLIC_APP_ENV;
  if (env === 'production') {
    return getProductionPaymentMethodName(pmId);
  } else {
    return getDevelopmentPaymentMethodName(pmId);
  }
};

export type CARD_PAYMENT_METHOD_TYPES =
  | 'DEBIT_CARD'
  | 'CREDIT_CARD'
  | 'AMEX'
  | 'APPLE_PAY'
  | 'CARTE_BANCAIRE';

export type PAYMENT_METHOD_TYPES =
  | CARD_PAYMENT_METHOD_TYPES
  | 'UPI'
  | 'NET_BANKING'
  | 'WALLETS'
  | 'DUMMY_PAY'
  | 'APM'
  | 'PAYPAL'
  | 'GIROPAY'
  | 'KAKAOPAY'
  | 'BOLETO'
  | 'REDPAGOS'
  | 'RAPI_PAGO'
  | 'OXXO'
  | 'PAGO_EFECTIVO'
  | 'PSE'
  | 'SAFETYPAY'
  | 'RAPID_TRANSFER'
  | 'EPAY_BULGARIA_DIRECT'
  | 'NETELLER'
  | 'PAYCONIQ'
  | 'MBWAY'
  | 'MULTIBANCO'
  | 'AIRCASHPAY'
  | 'SATISPAY'
  | 'SEPA'
  | 'SOFORT'
  | 'IDEAL'
  | 'PAYTM'
  | 'P24'
  | 'EPS'
  | 'BANCONTACT'
  | 'WECHAT_PAY'
  | 'GRAB_PAY'
  | 'PAYNOW_QR'
  | 'PROMPT_PAY_QR'
  | 'ALIPAY'
  | 'POLI'
  | 'PAYMAYA'
  | 'SHOPEE_PAY'
  | 'FPX'
  | 'BLIK'
  | 'INSTANT_BANK_TRANSFER'
  // | 'INSTANT_BANK_TRANSFER_FI'
  | 'OVO'
  | 'DANA'
  | 'QRIS'
  | 'LINK_AJA'
  | 'CHINA_UNION_PAY'
  | 'ZONDA_CRYPTO'
  | 'PIX_QR'
  | 'GOOGLE_PAY'
  | 'SKRILL_WALLET'
  | 'PAYSAFE_CARD'
  | 'MYBANK'
  | 'TRUSTLY'
  | 'BANK_TRANSFER_EUROPE'
  | 'BANK_TRANSFER_PERU'
  | 'ATM_TRANSFER_INDONESIA'
  | 'DOKU_WALLET'
  | 'JENIUS_PAY'
  | 'BANK_TRANSFER_THAILAND'
  | 'KPLUS'
  | 'CENPAY'
  | 'BOONTERM_KIOSK'
  | 'SHOPEE_PAY_THAILAND'
  | 'SHOPEE_PAY_PHILIPPINES'
  | 'SCB_EASY'
  | 'KRUNGSRI_MOBILE_APPLICATION'
  | 'KOREAN_VIRTUAL_ACCOUNT'
  | 'BANK_TRANSFER_KOREA'
  | 'BANK_TRANSFER_POLAND'
  | 'KONBINI'
  | 'PAY_EASY'
  | 'DRIFFLE_WALLET'
  | 'TRUEMONEY';

export const PAYMENT_METHOD_NAMES: {
  [key in PAYMENT_METHOD_TYPES]: PAYMENT_METHOD_TYPES;
} = {
  DEBIT_CARD: 'DEBIT_CARD',
  CREDIT_CARD: 'CREDIT_CARD',
  UPI: 'UPI',
  NET_BANKING: 'NET_BANKING',
  WALLETS: 'WALLETS',
  DUMMY_PAY: 'DUMMY_PAY',
  APM: 'APM',
  PAYPAL: 'PAYPAL',
  GIROPAY: 'GIROPAY',
  RAPID_TRANSFER: 'RAPID_TRANSFER',
  EPAY_BULGARIA_DIRECT: 'EPAY_BULGARIA_DIRECT',
  NETELLER: 'NETELLER',
  PAYCONIQ: 'PAYCONIQ',
  MBWAY: 'MBWAY',
  MULTIBANCO: 'MULTIBANCO',
  AIRCASHPAY: 'AIRCASHPAY',
  SATISPAY: 'SATISPAY',
  MYBANK: 'MYBANK',
  SEPA: 'SEPA',
  SOFORT: 'SOFORT',
  IDEAL: 'IDEAL',
  PAYTM: 'PAYTM',
  CARTE_BANCAIRE: 'CARTE_BANCAIRE',
  AMEX: 'AMEX',
  P24: 'P24',
  EPS: 'EPS',
  BANCONTACT: 'BANCONTACT',
  WECHAT_PAY: 'WECHAT_PAY',
  GRAB_PAY: 'GRAB_PAY',
  PAYNOW_QR: 'PAYNOW_QR',
  PROMPT_PAY_QR: 'PROMPT_PAY_QR',
  ALIPAY: 'ALIPAY',
  POLI: 'POLI',
  PAYMAYA: 'PAYMAYA',
  SHOPEE_PAY: 'SHOPEE_PAY',
  SHOPEE_PAY_PHILIPPINES: 'SHOPEE_PAY_PHILIPPINES',
  FPX: 'FPX',
  BLIK: 'BLIK',
  INSTANT_BANK_TRANSFER: 'INSTANT_BANK_TRANSFER',
  // INSTANT_BANK_TRANSFER_FI: 'INSTANT_BANK_TRANSFER_FI',
  OVO: 'OVO',
  DANA: 'DANA',
  QRIS: 'QRIS',
  LINK_AJA: 'LINK_AJA',
  CHINA_UNION_PAY: 'CHINA_UNION_PAY',
  ZONDA_CRYPTO: 'ZONDA_CRYPTO',
  PIX_QR: 'PIX_QR',
  GOOGLE_PAY: 'GOOGLE_PAY',
  SKRILL_WALLET: 'SKRILL_WALLET',
  PAYSAFE_CARD: 'PAYSAFE_CARD',
  TRUSTLY: 'TRUSTLY',
  SAFETYPAY: 'SAFETYPAY',
  KAKAOPAY: 'KAKAOPAY',
  BOLETO: 'BOLETO',
  RAPI_PAGO: 'RAPI_PAGO',
  REDPAGOS: 'REDPAGOS',
  OXXO: 'OXXO',
  PAGO_EFECTIVO: 'PAGO_EFECTIVO',
  PSE: 'PSE',
  BANK_TRANSFER_EUROPE: 'BANK_TRANSFER_EUROPE',
  BANK_TRANSFER_PERU: 'BANK_TRANSFER_PERU',
  ATM_TRANSFER_INDONESIA: 'ATM_TRANSFER_INDONESIA',
  DOKU_WALLET: 'DOKU_WALLET',
  JENIUS_PAY: 'JENIUS_PAY',
  BANK_TRANSFER_THAILAND: 'BANK_TRANSFER_THAILAND',
  KPLUS: 'KPLUS',
  CENPAY: 'CENPAY',
  BOONTERM_KIOSK: 'BOONTERM_KIOSK',
  SHOPEE_PAY_THAILAND: 'SHOPEE_PAY_THAILAND',
  SCB_EASY: 'SCB_EASY',
  KRUNGSRI_MOBILE_APPLICATION: 'KRUNGSRI_MOBILE_APPLICATION',
  KOREAN_VIRTUAL_ACCOUNT: 'KOREAN_VIRTUAL_ACCOUNT',
  BANK_TRANSFER_KOREA: 'BANK_TRANSFER_KOREA',
  KONBINI: 'KONBINI',
  PAY_EASY: 'PAY_EASY',
  BANK_TRANSFER_POLAND: 'BANK_TRANSFER_POLAND',
  APPLE_PAY: 'APPLE_PAY',
  DRIFFLE_WALLET: 'DRIFFLE_WALLET',
  TRUEMONEY:'TRUEMONEY'
};

const getProductionPaymentMethodName = (pmId: number): PAYMENT_METHOD_TYPES => {
  switch (pmId) {
    case 1: {
      return PAYMENT_METHOD_NAMES.DEBIT_CARD;
    }
    case 2: {
      return PAYMENT_METHOD_NAMES.CREDIT_CARD;
    }
    case 3: {
      return PAYMENT_METHOD_NAMES.UPI;
    }
    case 4: {
      return PAYMENT_METHOD_NAMES.NET_BANKING;
    }
    case 5: {
      return PAYMENT_METHOD_NAMES.WALLETS;
    }
    case 11: {
      return PAYMENT_METHOD_NAMES.PAYPAL;
    }
    case 12: {
      return PAYMENT_METHOD_NAMES.GIROPAY;
    }
    case 13: {
      return PAYMENT_METHOD_NAMES.IDEAL;
    }
    case 14: {
      return PAYMENT_METHOD_NAMES.PAYTM;
    }
    case 15: {
      return PAYMENT_METHOD_NAMES.CARTE_BANCAIRE;
    }
    case 16: {
      return PAYMENT_METHOD_NAMES.AMEX;
    }
    case 17: {
      return PAYMENT_METHOD_NAMES.P24;
    }
    case 18: {
      return PAYMENT_METHOD_NAMES.EPS;
    }
    case 19: {
      return PAYMENT_METHOD_NAMES.BANCONTACT;
    }
    case 20: {
      return PAYMENT_METHOD_NAMES.WECHAT_PAY;
    }
    case 22: {
      return PAYMENT_METHOD_NAMES.GRAB_PAY;
    }
    case 24: {
      return PAYMENT_METHOD_NAMES.PAYNOW_QR;
    }
    case 25: {
      return PAYMENT_METHOD_NAMES.PROMPT_PAY_QR;
    }
    case 26: {
      return PAYMENT_METHOD_NAMES.ALIPAY;
    }
    case 27: {
      return PAYMENT_METHOD_NAMES.POLI;
    }
    case 28: {
      return PAYMENT_METHOD_NAMES.PAYMAYA;
    }
    case 29: {
      return PAYMENT_METHOD_NAMES.SHOPEE_PAY;
    }
    case 30: {
      return PAYMENT_METHOD_NAMES.FPX;
    }
    case 31: {
      return PAYMENT_METHOD_NAMES.BLIK;
    }
    case 32: {
      return PAYMENT_METHOD_NAMES.INSTANT_BANK_TRANSFER;
    }
    case 33: {
      return PAYMENT_METHOD_NAMES.OVO;
    }
    case 34: {
      return PAYMENT_METHOD_NAMES.DANA;
    }
    case 35: {
      return PAYMENT_METHOD_NAMES.QRIS;
    }
    case 36: {
      return PAYMENT_METHOD_NAMES.LINK_AJA;
    }
    case 37: {
      return PAYMENT_METHOD_NAMES.CHINA_UNION_PAY;
    }
    case 38: {
      return PAYMENT_METHOD_NAMES.ZONDA_CRYPTO;
    }
    case 39: {
      return PAYMENT_METHOD_NAMES.PIX_QR;
    }
    case 45: {
      return PAYMENT_METHOD_NAMES.MYBANK;
    }
    case 44: {
      return PAYMENT_METHOD_NAMES.SATISPAY;
    }
    case 40: {
      return PAYMENT_METHOD_NAMES.AIRCASHPAY;
    }
    // case 45: {
    //   return PAYMENT_METHOD_NAMES.INSTANT_BANK_TRANSFER_FI;
    // }
    case 41: {
      return PAYMENT_METHOD_NAMES.MULTIBANCO;
    }
    case 42: {
      return PAYMENT_METHOD_NAMES.MBWAY;
    }
    case 43: {
      return PAYMENT_METHOD_NAMES.PAYCONIQ;
    }
    case 46: {
      return PAYMENT_METHOD_NAMES.PAYSAFE_CARD;
    }
    case 47: {
      return PAYMENT_METHOD_NAMES.TRUSTLY;
    }
    case 48: {
      return PAYMENT_METHOD_NAMES.SOFORT;
    }
    case 49: {
      return PAYMENT_METHOD_NAMES.NETELLER;
    }
    case 50: {
      return PAYMENT_METHOD_NAMES.RAPID_TRANSFER;
    }
    case 51: {
      return PAYMENT_METHOD_NAMES.EPAY_BULGARIA_DIRECT;
    }
    case 55: {
      return PAYMENT_METHOD_NAMES.SAFETYPAY;
    }
    case 56: {
      return PAYMENT_METHOD_NAMES.KAKAOPAY;
    }
    case 57: {
      return PAYMENT_METHOD_NAMES.BOLETO;
    }
    case 58: {
      return PAYMENT_METHOD_NAMES.REDPAGOS;
    }
    case 59: {
      return PAYMENT_METHOD_NAMES.RAPI_PAGO;
    }
    case 60: {
      return PAYMENT_METHOD_NAMES.OXXO;
    }
    case 61: {
      return PAYMENT_METHOD_NAMES.PAGO_EFECTIVO;
    }
    case 62: {
      return PAYMENT_METHOD_NAMES.PSE;
    }
    case 63: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_EUROPE;
    }
    case 64: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_PERU;
    }
    case 65: {
      return PAYMENT_METHOD_NAMES.ATM_TRANSFER_INDONESIA;
    }
    case 66: {
      return PAYMENT_METHOD_NAMES.DOKU_WALLET;
    }
    case 67: {
      return PAYMENT_METHOD_NAMES.JENIUS_PAY;
    }
    case 68: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_THAILAND;
    }
    case 69: {
      return PAYMENT_METHOD_NAMES.KPLUS;
    }
    case 70: {
      return PAYMENT_METHOD_NAMES.CENPAY;
    }
    case 71: {
      return PAYMENT_METHOD_NAMES.BOONTERM_KIOSK;
    }
    case 72: {
      return PAYMENT_METHOD_NAMES.SHOPEE_PAY_THAILAND;
    }
    case 73: {
      return PAYMENT_METHOD_NAMES.SCB_EASY;
    }
    case 74: {
      return PAYMENT_METHOD_NAMES.KRUNGSRI_MOBILE_APPLICATION;
    }
    case 75: {
      return PAYMENT_METHOD_NAMES.KOREAN_VIRTUAL_ACCOUNT;
    }
    case 76: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_KOREA;
    }
    case 77: {
      return PAYMENT_METHOD_NAMES.KONBINI;
    }
    case 78: {
      return PAYMENT_METHOD_NAMES.PAY_EASY;
    }
    case 79: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_POLAND;
    }
    case 83: {
      return PAYMENT_METHOD_NAMES.GOOGLE_PAY;
    }
    case 84: {
      return PAYMENT_METHOD_NAMES.DRIFFLE_WALLET;
    }
    case 85: {
      return PAYMENT_METHOD_NAMES.APPLE_PAY;
    }
    case 87: {
      return PAYMENT_METHOD_NAMES.TRUEMONEY;
    }
    case 88: {
      return PAYMENT_METHOD_NAMES.SHOPEE_PAY_PHILIPPINES;
    }
    
  }
  throw Error('pmid not found');
};

const getDevelopmentPaymentMethodName = (
  pmId: number
): PAYMENT_METHOD_TYPES => {
  switch (pmId) {
    case 1: {
      return PAYMENT_METHOD_NAMES.DEBIT_CARD;
    }
    case 2: {
      return PAYMENT_METHOD_NAMES.CREDIT_CARD;
    }
    case 3: {
      return PAYMENT_METHOD_NAMES.UPI;
    }
    case 4: {
      return PAYMENT_METHOD_NAMES.NET_BANKING;
    }
    case 5: {
      return PAYMENT_METHOD_NAMES.WALLETS;
    }
    case 11: {
      return PAYMENT_METHOD_NAMES.PAYPAL;
    }
    case 12: {
      return PAYMENT_METHOD_NAMES.GIROPAY;
    }
    case 13: {
      return PAYMENT_METHOD_NAMES.IDEAL;
    }
    case 14: {
      return PAYMENT_METHOD_NAMES.PAYTM;
    }
    case 15: {
      return PAYMENT_METHOD_NAMES.CARTE_BANCAIRE;
    }
    case 16: {
      return PAYMENT_METHOD_NAMES.AMEX;
    }
    case 17: {
      return PAYMENT_METHOD_NAMES.P24;
    }
    case 18: {
      return PAYMENT_METHOD_NAMES.EPS;
    }
    case 19: {
      return PAYMENT_METHOD_NAMES.BANCONTACT;
    }
    case 20: {
      return PAYMENT_METHOD_NAMES.WECHAT_PAY;
    }
    case 22: {
      return PAYMENT_METHOD_NAMES.GRAB_PAY;
    }
    case 24: {
      return PAYMENT_METHOD_NAMES.PAYNOW_QR;
    }
    case 25: {
      return PAYMENT_METHOD_NAMES.PROMPT_PAY_QR;
    }
    case 26: {
      return PAYMENT_METHOD_NAMES.ALIPAY;
    }
    case 27: {
      return PAYMENT_METHOD_NAMES.POLI;
    }
    case 28: {
      return PAYMENT_METHOD_NAMES.PAYMAYA;
    }
    case 29: {
      return PAYMENT_METHOD_NAMES.SHOPEE_PAY;
    }
    case 30: {
      return PAYMENT_METHOD_NAMES.FPX;
    }
    case 31: {
      return PAYMENT_METHOD_NAMES.BLIK;
    }
    case 32: {
      return PAYMENT_METHOD_NAMES.INSTANT_BANK_TRANSFER;
    }
    case 33: {
      return PAYMENT_METHOD_NAMES.OVO;
    }
    case 34: {
      return PAYMENT_METHOD_NAMES.DANA;
    }
    case 35: {
      return PAYMENT_METHOD_NAMES.QRIS;
    }
    case 36: {
      return PAYMENT_METHOD_NAMES.LINK_AJA;
    }
    case 37: {
      return PAYMENT_METHOD_NAMES.CHINA_UNION_PAY;
    }
    case 38: {
      return PAYMENT_METHOD_NAMES.ZONDA_CRYPTO;
    }
    case 39: {
      return PAYMENT_METHOD_NAMES.PIX_QR;
    }
    case 40: {
      return PAYMENT_METHOD_NAMES.DUMMY_PAY;
    }
    case 41: {
      return PAYMENT_METHOD_NAMES.GOOGLE_PAY;
    }
    case 43: {
      return PAYMENT_METHOD_NAMES.MYBANK;
    }
    case 44: {
      return PAYMENT_METHOD_NAMES.SATISPAY;
    }
    case 45: {
      return PAYMENT_METHOD_NAMES.AIRCASHPAY;
    }
    // case 45: {
    //   return PAYMENT_METHOD_NAMES.INSTANT_BANK_TRANSFER_FI;
    // }
    case 46: {
      return PAYMENT_METHOD_NAMES.MULTIBANCO;
    }
    case 47: {
      return PAYMENT_METHOD_NAMES.MBWAY;
    }
    case 48: {
      return PAYMENT_METHOD_NAMES.PAYCONIQ;
    }
    case 42: {
      return PAYMENT_METHOD_NAMES.SKRILL_WALLET;
    }
    case 50: {
      return PAYMENT_METHOD_NAMES.PAYSAFE_CARD;
    }
    case 51: {
      return PAYMENT_METHOD_NAMES.TRUSTLY;
    }
    case 52: {
      return PAYMENT_METHOD_NAMES.SOFORT;
    }
    case 53: {
      return PAYMENT_METHOD_NAMES.NETELLER;
    }
    case 54: {
      return PAYMENT_METHOD_NAMES.RAPID_TRANSFER;
    }
    case 55: {
      return PAYMENT_METHOD_NAMES.EPAY_BULGARIA_DIRECT;
    }
    case 59: {
      return PAYMENT_METHOD_NAMES.SAFETYPAY;
    }
    case 60: {
      return PAYMENT_METHOD_NAMES.KAKAOPAY;
    }
    case 61: {
      return PAYMENT_METHOD_NAMES.BOLETO;
    }
    case 62: {
      return PAYMENT_METHOD_NAMES.REDPAGOS;
    }
    case 63: {
      return PAYMENT_METHOD_NAMES.RAPI_PAGO;
    }
    case 64: {
      return PAYMENT_METHOD_NAMES.OXXO;
    }
    case 65: {
      return PAYMENT_METHOD_NAMES.PAGO_EFECTIVO;
    }
    case 66: {
      return PAYMENT_METHOD_NAMES.PSE;
    }
    case 67: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_EUROPE;
    }
    case 68: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_PERU;
    }
    case 69: {
      return PAYMENT_METHOD_NAMES.ATM_TRANSFER_INDONESIA;
    }
    case 70: {
      return PAYMENT_METHOD_NAMES.DOKU_WALLET;
    }
    case 71: {
      return PAYMENT_METHOD_NAMES.JENIUS_PAY;
    }
    case 72: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_THAILAND;
    }
    case 73: {
      return PAYMENT_METHOD_NAMES.KPLUS;
    }
    case 74: {
      return PAYMENT_METHOD_NAMES.CENPAY;
    }
    case 75: {
      return PAYMENT_METHOD_NAMES.BOONTERM_KIOSK;
    }
    case 76: {
      return PAYMENT_METHOD_NAMES.SHOPEE_PAY_THAILAND;
    }
    case 77: {
      return PAYMENT_METHOD_NAMES.SCB_EASY;
    }
    case 78: {
      return PAYMENT_METHOD_NAMES.KRUNGSRI_MOBILE_APPLICATION;
    }
    case 79: {
      return PAYMENT_METHOD_NAMES.KOREAN_VIRTUAL_ACCOUNT;
    }
    case 80: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_KOREA;
    }
    case 81: {
      return PAYMENT_METHOD_NAMES.KONBINI;
    }
    case 82: {
      return PAYMENT_METHOD_NAMES.PAY_EASY;
    }
    case 83: {
      return PAYMENT_METHOD_NAMES.BANK_TRANSFER_POLAND;
    }
    case 85: {
      return PAYMENT_METHOD_NAMES.APPLE_PAY;
    }
    case 89: {
      return PAYMENT_METHOD_NAMES.TRUEMONEY;
    }
    case 90: {
      return PAYMENT_METHOD_NAMES.SHOPEE_PAY_PHILIPPINES;
    }
  }
  throw Error('pmid not found');
};

export const getCardPaymentMethodIds = () => {
  if (process.env.NODE_ENV === 'production') {
    return [1, 2, 15, 16, 83, 85];
  }
  return [1, 2, 15, 16, 41, 85];
};

export const getRiskyPaymentMethodIds = () => {
  if (process.env.NODE_ENV === 'production') {
    return [1, 2, 15, 16, 11];
  }
  return [1, 2, 15, 16, 11];
};

export const asyncPaymentMethodNames = () => {
  return [PAYMENT_METHOD_NAMES.DUMMY_PAY, PAYMENT_METHOD_NAMES.ZONDA_CRYPTO];
};

export const getCardPaymentMethodNames = () => {
  return [
    PAYMENT_METHOD_NAMES.CREDIT_CARD,
    PAYMENT_METHOD_NAMES.DEBIT_CARD,
    PAYMENT_METHOD_NAMES.CARTE_BANCAIRE,
    PAYMENT_METHOD_NAMES.AMEX,
    PAYMENT_METHOD_NAMES.GOOGLE_PAY,
    PAYMENT_METHOD_NAMES.APPLE_PAY,
  ];
};

export const bankTransferPaymentMethods = (): Array<string> => {
  return [
    // PAYMENT_METHOD_NAMES.QRIS,
    // PAYMENT_METHOD_NAMES.DANA,
    // PAYMENT_METHOD_NAMES.OVO,
    // PAYMENT_METHOD_NAMES.LINK_AJA,
  ];
};

export const disablesMobilePayButtonPaymentMethods = (
  paymentMethod: PAYMENT_METHOD_TYPES,
  paymentGateway: PAYMENT_GATEWAYS_TYPE | undefined | null
): boolean => {
  if (
    [PAYMENT_METHOD_NAMES.GOOGLE_PAY, PAYMENT_METHOD_NAMES.PAYPAL].includes(
      paymentMethod
    ) ||
    (getCardPaymentMethodNames().includes(paymentMethod) &&
      paymentGateway === PAYMENT_GATEWAYS.MANGOPAY_EU)
  ) {
    return true;
  }

  return false;
};
