export type PAYMENT_GATEWAYS_TYPE =
  | 'STRIPE'
  | 'CASHFREE'
  | 'STRIPE_UK'
  | 'NUVEI'
  | 'NUVEI_EU'
  | 'HIPAY_EUR'
  | 'HIPAY_UAB_EUR'
  | 'HIPAY_GBP'
  | 'HIPAY_USD'
  | 'PAYPAL'
  | 'DUMMY_PAY'
  | 'STRIPE_UK_NEW'
  | 'CHECKOUT_COM'
  | 'TAZAPAY'
  | 'STRIPE_EU'
  | 'ZONDA_CRYPTO'
  | 'SKRILL'
  | 'TRUSTPAY'
  | 'PAYPAL_EU'
  | 'MANGOPAY_EU'
  | 'TAZAPAY_EU'
  | 'PAYMENT_WALL'
  | 'CHECKOUT_COM_EU';

// export type PAYMENT_GATEWAYS_KEYS = keyof typeof PAYMENT_GATEWAYS;

export const PAYMENT_GATEWAYS: {
  [key in PAYMENT_GATEWAYS_TYPE]: PAYMENT_GATEWAYS_TYPE;
} = {
  STRIPE: 'STRIPE',
  CASHFREE: 'CASHFREE',
  STRIPE_UK: 'STRIPE_UK',
  STRIPE_UK_NEW: 'STRIPE_UK_NEW',
  NUVEI: 'NUVEI',
  NUVEI_EU: 'NUVEI_EU',
  HIPAY_EUR: 'HIPAY_EUR',
  HIPAY_UAB_EUR: 'HIPAY_UAB_EUR',
  HIPAY_GBP: 'HIPAY_GBP',
  HIPAY_USD: 'HIPAY_USD',
  PAYPAL: 'PAYPAL',
  DUMMY_PAY: 'DUMMY_PAY',
  CHECKOUT_COM: 'CHECKOUT_COM',
  TAZAPAY: 'TAZAPAY',
  STRIPE_EU: 'STRIPE_EU',
  ZONDA_CRYPTO: 'ZONDA_CRYPTO',
  SKRILL: 'SKRILL',
  TRUSTPAY: 'TRUSTPAY',
  PAYPAL_EU: 'PAYPAL_EU',
  MANGOPAY_EU: 'MANGOPAY_EU',
  TAZAPAY_EU: 'TAZAPAY_EU',
  PAYMENT_WALL: 'PAYMENT_WALL',
  CHECKOUT_COM_EU: 'CHECKOUT_COM_EU',
};

export const getAllStripeGateways = () => {
  return [
    PAYMENT_GATEWAYS.STRIPE,
    PAYMENT_GATEWAYS.STRIPE_UK,
    PAYMENT_GATEWAYS.STRIPE_UK_NEW,
    PAYMENT_GATEWAYS.STRIPE_EU,
  ];
};

export const getAllHipayGateways = () => {
  return [
    PAYMENT_GATEWAYS.HIPAY_EUR,
    PAYMENT_GATEWAYS.HIPAY_GBP,
    PAYMENT_GATEWAYS.HIPAY_USD,
    PAYMENT_GATEWAYS.HIPAY_UAB_EUR,
  ];
};

export const getAllPaypalGateways = () => {
  return [PAYMENT_GATEWAYS.PAYPAL, PAYMENT_GATEWAYS.PAYPAL_EU];
};

export const getAllTazapayGateways = () => {
  return [PAYMENT_GATEWAYS.TAZAPAY, PAYMENT_GATEWAYS.TAZAPAY_EU];
};

export const getAllNuveiGateways = () => {
  return [PAYMENT_GATEWAYS.NUVEI, PAYMENT_GATEWAYS.NUVEI_EU];
};

export const getAllCheckoutGateways = () => {
  return [PAYMENT_GATEWAYS.CHECKOUT_COM_EU, PAYMENT_GATEWAYS.CHECKOUT_COM];
};
